<template>
  <section>
    <div class="logo">
      <router-link to="/">
        <img
          src="@/assets/images/logo-lowco.png"
          alt="Lowco logo"
        >
      </router-link>
    </div>
    <PasswordResetForm
      v-if="$route.query.token && $route.query.userId"
      :isEmailConfirmation="isEmailConfirmation"
    />
    <PasswordLostForm v-else-if="!isEmailConfirmation" />
  </section>
</template>

<script>
import PasswordLostForm from '../components/passwordLost/PasswordLostForm.vue';
import PasswordResetForm from '../components/passwordLost/PasswordResetForm.vue';

export default {
  name: 'PasswordLost',
  components: {
    PasswordLostForm,
    PasswordResetForm,
  },
  data() {
    return {
      isEmailConfirmation: document.location.href.includes('confirm-email'),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/common/variables.scss";
@import "../assets/styles/common/mixins.scss";

@include centeredLayoutWithLogo();
</style>
